<template>
  <div
    v-if="!showInMap"
    class="card bg-light swiper-slide"
  >
    <div class="card-header main-header-container py-1">
      <div>
        <div class="name-tag font-16">
          <p class="clamp-2 mb-0 text-uppercase">
            {{ item.user.first_name }} {{ item.user.last_name }}
          </p>
          <p class="clamp-2 mb-0">
            {{ item.store_order_invoice_id }}
          </p>
        </div>
      </div>
      <div class="payment-mode-images d-flex">
        <div class="d-flex align-items-center flex-column justify-content-between text-center">
          <template v-if="!isEmpty(item.invoice)">
          <img
            v-if="item.invoice.payment_mode.toLowerCase() === 'cash'"
            :src="$helpers.getIcons('cash')"
            title="Cash Payment"
          >
          <img
            v-if="item.invoice.payment_mode.toLowerCase() === 'card'"
            :src="$helpers.getIcons('card')"
            title="Card Payment"
          >
          <img
            v-if="item.invoice.payment_mode.toLowerCase() === 'wallet'"
            :src="$helpers.getIcons('wallet')"
            title="Wallet Payment"
          >
          <img
            v-if="item.invoice.payment_mode.toLowerCase() === 'on_delivery'"
            :src="$helpers.getIcons('card-on-delivery')"
            title="Card on delivery Payment"
          >
        </template>
          <p class="mb-0 payment-mode-images text-uppercase">
            {{ item.paid == 1 ? 'Paid' : 'Not Paid' }}
          </p>
        </div>
      </div>
      <div class="rate-block font-16">
        <span class="price-tag"> {{ userCurrency }}{{ invoiceTotal.total_amount }} </span>
        <span class="light-text"> {{ item.estimated_delivery_time ? item.estimated_delivery_time : item.delivery_time }} </span>
      </div>
    </div>
    <div class="card-body bg-white">
      <div class="d-flex justify-content-between align-items-start pt-1">
        <div>
          <p class="m-0 p-0 text-muted">{{ $t('PICK UP') }}</p>
        </div>
        <div>
          <a
            :href="`https://maps.google.com/?q=${item.pickup.latitude},${item.pickup.longitude}`"
            class="text-underline text-dark mr-5px"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/app-icons/map.png')"
              width="32"
              title="View Map"
            >
          </a>
          <img
            width="30"
            class="cursor-pointer mr-5px"
            :src="require('@/assets/images/app-icons/Call_New.png')"
            @click="storeActions('call')"
            title="Call"
          >
          <img
            width="30"
            class="cursor-pointer"
            :src="require('@/assets/images/app-icons/Chat_New.png')"
            @click="storeActions('message')"
            title="Chat"
          >
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-start flex-column">
          <div class="font-16">
            <p class="m-0 p-0 text-capitalize">
              <strong>{{ item.pickup ? item.pickup.store_name : '' }}</strong>
            </p>
          </div>
          <div class="font-16">
            <p class="m-0 p-0">
              {{ item.pickup ? item.pickup.store_location : '' }}
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div class="d-flex justify-content-between align-items-start pt-1">
        <div>
          <p class="m-0 p-0 text-muted">{{ $t('DROP OFF') }}</p>
        </div>
        <div>
          <img
            v-if="item.contactless_delivery"
            :src="$helpers.getIcons('contact-less')"
            height="24"
            title="Contact less delivery"
          >
        </div>
        <div>
          <a
            :href="`https://maps.google.com/?q=${item.delivery.latitude},${item.delivery.longitude}`"
            class="mr-5px text-underline text-dark"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/app-icons/map.png')"
              width="32"
              title="View Map"
            >
          </a>
          <img
            width="30"
            class="cursor-pointer mr-5px"
            :src="require('@/assets/images/app-icons/Call_New.png')"
            @click="userActions('call')"
            title="Call"
          >
          <img
            width="30"
            class="cursor-pointer"
            :src="require('@/assets/images/app-icons/Chat_New.png')"
            @click="userActions('message')"
            title="Chat"
          >
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-start flex-column">
          <div class="font-16">
            <p class="m-0 p-0 text-capitalize">
              <strong>{{ item.user.first_name }} {{ item.user.last_name }}</strong>
            </p>
          </div>
          <div class="font-16">
            <p class="m-0 p-0">
              {{ item.delivery.map_address }}
            </p>
            <p class="m-0 p-0">
              {{ $t('Flat No') }}: {{ item.delivery.flat_no }}
            </p>
            <p class="m-0 p-0">
              {{ $t('Building/Villa Name') }}: {{ item.delivery.building }}
            </p>
            <p class="m-0 p-0">
              {{ $t('Street') }}: {{ item.delivery.street }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-footer bottom-button-block bg-white d-flex align-items-center"
      :class="{ 'justify-content-between': showTimer, 'justify-content-end': !showTimer }"
    >
      <p
        v-if="showTimer"
        class="mb-0 d-inline"
      >
        {{ $t('Time remaining') }}: {{ countDown }} {{ $t('secs') }}
      </p>
      <div class="wrapper-card-actions-button">
        <img
            :src="require('@/assets/images/app-icons/seo.png')"
            class="mr-3"
            width="32"
            @click="that.openViewOrder(item)"
          >
        <button
          v-if="showIgnore"
          type="button"
          class="btn btn-danger mr-2"
          @click="that.ignoreOrder(item)"
        >
          {{ $t('Ignore') }}
        </button>
        <button
          v-if="nextStep !== 'Delivered or Reject'"
          type="button"
          class="btn btn-primary"
          @click="that.updateRequestApproval(item, nextStepStatus)"
        >
          {{ $t(nextStep) }}
        </button>
        <button
          v-if="nextStep === 'Delivered or Reject'"
          type="button"
          class="btn btn-primary"
          @click="that.updateRequestApproval(item, 'COMPLETED')"
        >
          {{ $t('Delivered') }}
        </button>
        <button
          v-if="nextStep === 'Delivered or Reject'"
          type="button"
          class="btn btn-danger ml-1"
          @click="that.updateRequestApproval(item, 'REJECTED')"
        >
          {{ $t('Reject') }}
        </button>
      </div>
    </div>
  </div>
  <div
    v-else
    class="card bg-light swiper-slide map-card-icon-view"
  >
    <div class="card-header main-header-container py-1 d-flex justify-content-between">
      <div class="name-tag">
        <p class="mb-0">
          {{ item.store_order_invoice_id }}
        </p>
      </div>
      <div class="rate-block d-flex">
        <span class="price-tag mr-1 font-17"> {{ userCurrency }}{{ invoiceTotal.total_amount }} </span>
        <feather-icon
          icon="XIcon"
          size="20"
          @click="hideFromMap"
        />
      </div>
    </div>
    <div class="card-body bg-white">
      <div
        v-if="!isEmpty(item.invoice)"
        class="mt-1"
      >
        <p class="mb-0 payment-mode-images font-15">
          {{ $t('Payment mode: ') }} {{ item.invoice.payment_mode }} <span v-if="item.contactless_delivery">({{ $t('Contactless Delivery') }})</span>
          <span class="d-flex">
            <img
              v-if="item.invoice.payment_mode === 'CASH'"
              class="ml-1"
              :src="$helpers.getIcons('cash')"
            >
            <img
              v-if="item.invoice.payment_mode === 'CARD'"
              class="ml-1"
              :src="$helpers.getIcons('card')"
            >
            <img
              v-if="item.invoice.payment_mode === 'on_delivery'"
              class="ml-1"
              :src="$helpers.getIcons('card-on-delivery')"
            >
            <img
              v-if="item.invoice.payment_mode === 'contact_less'"
              class="ml-1"
              :src="$helpers.getIcons('contact-less')"
              style="width: 80px;"
            >
          </span>
        </p>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="wrapper-agent-pickup-location">
          <div class="light-text pt-1">
            <p class="mb-0">
              <b>{{ $t('PICK UP') }}</b> ({{ item.pickup ? item.pickup.store_name : '' }})
            </p>
          </div>
          <div class="pickup-area-name">
            <p
              class="mb-0 text-underline"
              @click="openLocation(item.pickup)"
            >
              {{ item.pickup ? item.pickup.store_location : '' }}
            </p>
          </div>
        </div>
        <div class="wrapper-agent-pickup-location-actions d-flex">
          <img
            width="30"
            class="mx-1 cursor-pointer"
            :src="require('@/assets/images/app-icons/Call_New.png')"
            @click="storeActions('call')"
          >
          <img
            width="30"
            class="mx-1 cursor-pointer"
            :src="require('@/assets/images/app-icons/Chat_New.png')"
            @click="storeActions('message')"
          >
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="wrapper-agent-pickup-location">
          <div
            v-if="item.delivery"
            class="light-text pt-1"
          >
            <b>{{ $t('DROP OFF') }}</b>
          </div>
          <div
            v-if="item.delivery"
            class="dropoff-area-name"
          >
            <a
              :href="`https://www.google.com/maps/search/?api=1&query=${item.delivery.latitude},${item.delivery.longitude}`"
              class="mb-0 text-underline text-dark"
              target="_blank"
            >
              {{ item.delivery.map_address }}
            </a>
            <p class="m-0">
              {{ $t('Flat No') }}: {{ item.delivery.flat_no }}
            </p>
            <p class="m-0">
              {{ $t('Building/Villa Name') }}: {{ item.delivery.building }}
            </p>
            <p class="m-0">
              {{ $t('Street') }}: {{ item.delivery.street }}
            </p>
          </div>
        </div>
        <div class="wrapper-agent-pickup-location-actions d-flex">
          <img
            width="30"
            class="mx-1 cursor-pointer"
            :src="require('@/assets/images/app-icons/Call_New.png')"
            @click="userActions('call')"
          >
          <img
            width="30"
            class="mx-1 cursor-pointer"
            :src="require('@/assets/images/app-icons/Chat_New.png')"
            @click="userActions('message')"
          >
        </div>
      </div>
    </div>
    <div class="card-footer bottom-button-block bg-white d-flex align-items-center justify-content-end">
      <div class="wrapper-card-actions-button">
        <button
          v-if="nextStep !== 'Delivered or Reject'"
          type="button"
          class="btn btn-primary"
          @click="that.updateRequestApproval(item, nextStepStatus)"
        >
          {{ $t(nextStep) }}
        </button>
        <button
          v-if="nextStep === 'Delivered or Reject'"
          type="button"
          class="btn btn-primary"
          @click="that.updateRequestApproval(item, 'COMPLETED')"
        >
          {{ $t('Delivered') }}
        </button>
        <button
          v-if="nextStep === 'Delivered or Reject'"
          type="button"
          class="btn btn-danger ml-1"
          @click="that.updateRequestApproval(item, 'REJECTED')"
        >
          {{ $t('Reject') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/libs/event-bus'
import { isEmpty } from '@/utilities'
export default {
  props: {
    that: {
      type: Object,
      default: () => {},
    },
    showIgnore: {
      type: Boolean,
      default: true,
    },
    showTimer: {
      type: Boolean,
      default: false,
    },
    showInMap: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    orderDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countDown: 60,
      interval: null,
    }
  },
  computed: {
    nextStep() {
      if (!this.item) return 'Accept'
      if (this.item.status === 'ASSIGNED') return 'On the way'
      if (this.item.status === 'PROCESSING') return 'Accept'
      if (this.item.status === 'STARTED') return 'At pickup location'
      if (this.item.status === 'REACHED') return 'Picked up'
      if (this.item.status === 'PICKEDUP') return 'Out for delivery'
      if (this.item.status === 'OUT_FOR_DELIVERY') return 'Arrived'
      // add two buttons for delivered or rejected
      if (this.item.status === 'ARRIVED') return 'Delivered or Reject'
      if (this.item.status === 'DELIVERED') return 'Order Completed'
      return 'Accept'
    },
    nextStepStatus() {
      if (!this.item) return ''
      if (this.item.status === 'PROCESSING') return ''
      if (this.item.status === 'ASSIGNED') return 'STARTED'
      if (this.item.status === 'STARTED') return 'REACHED'
      if (this.item.status === 'REACHED') return 'PICKEDUP'
      if (this.item.status === 'PICKEDUP') return 'OUT_FOR_DELIVERY'
      if (this.item.status === 'OUT_FOR_DELIVERY') return 'ARRIVED'
      // clicked on delivered
      if (this.item.status === 'ARRIVED') return 'DELIVERED'
      if (this.item.status === 'DELIVERED') return 'COMPLETED'
      return ''
    },
    getBase() {
      // eslint-disable-next-line no-nested-ternary
      return !isEmpty(this.item.invoice) ? this.item.invoice : this.item.order_invoice ? this.item.order_invoice : {}
    },
    userCurrency() {
      const cart = this.getBase
      if (cart.items && cart.items[0] && cart.items[0].store) {
        return cart.items[0].store.currency_symbol
      }
      return ''
    },
    invoiceTotal() {
      return this.getBase
    },
  },
  mounted() {
    this.countDownTimer()
  },
  destroyed() {
    if (this.interval) {
      clearTimeout(this.interval)
    }
  },
  methods: {
    isEmpty,
    storeActions(action) {
      if (action === 'message') {
        this.that.openStoreChat(this.item)
      } else if (action === 'call') {
        if (this.item && this.item.store && this.item.store.mobile) {
          window.open(`tel:+${this.item.store.country_code ? this.item.store.country_code : ''}${this.item.store.mobile}`)
        }
        if (this.item && this.item.store && this.item.store.contact_number) {
          window.open(`tel:+${this.item.store.country_code ? this.item.store.country_code : ''}${this.item.store.contact_number}`)
        }
      }
    },
    userActions(action) {
      if (action === 'message') {
        this.that.openUserChat(this.item)
        // if (this.item && this.item.user && this.item.user.mobile) {
        //   window.open(`https://wa.me/${this.item.user.country_code ? this.item.user.country_code : ''}${this.item.user.mobile}/?text=Hello`)
        // }
      } else if (action === 'call') {
        if (this.item && this.item.user && this.item.user.mobile) {
          window.open(`tel:+${this.item.user.country_code ? this.item.user.country_code : ''}${this.item.user.mobile}`)
        }
      }
    },
    showOnMap() {
      eventBus.$emit('show-details-on-map', this.item)
      this.$emit('hide-orders-modal')
    },
    hideFromMap() {
      eventBus.$emit('show-details-on-map', null)
    },
    openLocation(payload) {
      if (payload) {
        window.open(`https://maps.google.com/?q=${payload.latitude},${payload.longitude}`)
      }
    },
    countDownTimer() {
      if (this.showTimer) {
        if (this.countDown > 0) {
          this.interval = setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }
        if (this.countDown === 0) {
          this.that.ignoreOrder(this.item)
        }
      }
    },
    openCall() {
      if (this.orderDetail && this.orderDetail.user && this.orderDetail.user.mobile) {
        window.open(`tel:+${this.orderDetail.user.country_code ? this.orderDetail.user.country_code : ''}${this.orderDetail.user.mobile}`)
      }
    },
  },
}
</script>

<style scoped>
.payment-mode-images img {
  width: 30px;
}
.mr-5px{
  margin-right: 5px;
}
@media only screen and (max-width: 767px) {
  .map-card-icon-view {
    zoom: 0.6;
  }
}
</style>
