<template>
  <div v-if="!showInMap" class="card bg-light swiper-slide">
    <div class="card-header main-header-container py-1">
      <div>
        <div class="name-tag font-16">
          <p class="clamp-2 mb-0 text-uppercase">
            {{ item.user.first_name }} {{ item.user.last_name }}
          </p>
          <p class="clamp-2 mb-0">
            {{ item.booking_id }}
          </p>
        </div>
      </div>
      <div class="payment-mode-images d-flex">
        <div class="d-flex align-items-center flex-column justify-content-between text-center">
          <template v-if="!isEmpty(item.payment)">
            <img v-if="item.payment.payment_mode.toLowerCase() === 'cash'" :src="$helpers.getIcons('cash')"
              title="Cash Payment">
            <img v-if="item.payment.payment_mode.toLowerCase() === 'card'" :src="$helpers.getIcons('card')"
              title="Card Payment">
            <img v-if="item.payment.payment_mode.toLowerCase() === 'wallet'" :src="$helpers.getIcons('wallet')"
              title="Wallet Payment">
            <img v-if="item.payment.payment_mode.toLowerCase() === 'on_delivery'"
              :src="$helpers.getIcons('card-on-delivery')" title="Card on delivery Payment">
          </template>
          <p class="mb-0 payment-mode-images text-uppercase">
            {{ item.paid == 1 ? 'Paid' : 'Not Paid' }}
          </p>
        </div>
      </div>
      <div class="rate-block font-16">
        <span class="price-tag"> {{ userCurrency }}{{ invoiceTotal.payable }} </span>
      </div>
    </div>

    <div class="card-body bg-white">
      <div class="d-flex justify-content-between align-items-start pt-1">
        <div>
          <p class="m-0 p-0 text-muted">{{ $t('Provider') }}</p>
        </div>
        <div>
          <a
            :href="`https://maps.google.com/?q=${item.provider.latitude},${item.provider.longitude}`"
            class="text-underline text-dark mr-5px"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/app-icons/map.png')"
              width="32"
              title="View Map"
            >
          </a>
          <img
            width="30"
            class="cursor-pointer mr-5px"
            :src="require('@/assets/images/app-icons/Call_New.png')"
            @click="storeActions('call')"
            title="Call"
          >
          <img
            width="30"
            class="cursor-pointer"
            :src="require('@/assets/images/app-icons/Chat_New.png')"
            @click="storeActions('message')"
            title="Chat"
          >
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-start flex-column">
          <div class="font-16">
            <p class="m-0 p-0 text-capitalize">
              <strong>{{ item.provider ? item.provider.company_name : '' }}</strong>
            </p>
          </div>
          <div class="font-16">
            <p class="m-0 p-0">
              {{ item.provider ? item.provider.current_location : '' }}
            </p>
          </div>
        </div>
      </div>
      <hr v-if="item.onsite == 1" />
      <div v-if="item.onsite == 1" class="d-flex justify-content-between align-items-start pt-1">
        <div>
          <p class="m-0 p-0 text-muted">{{ $t('Customer') }}</p>
        </div>
        <div>
          <a
            :href="`https://maps.google.com/?q=${item.s_latitude},${item.s_longitude}`"
            class="mr-5px text-underline text-dark"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/app-icons/map.png')"
              width="32"
              title="View Map"
            >
          </a>
          <img
            width="30"
            class="cursor-pointer mr-5px"
            :src="require('@/assets/images/app-icons/Call_New.png')"
            @click="userActions('call')"
            title="Call"
          >
          <img
            width="30"
            class="cursor-pointer"
            :src="require('@/assets/images/app-icons/Chat_New.png')"
            @click="userActions('message')"
            title="Chat"
          >
        </div>
      </div>
      <div v-if="item.onsite == 1" class="d-flex justify-content-between">
        <div class="d-flex align-items-start flex-column">
          <div class="font-16">
            <p class="m-0 p-0 text-capitalize">
              <strong>{{ item.user.first_name }} {{ item.user.last_name }}</strong>
            </p>
          </div>
          <div class="font-16">
            <p class="m-0 p-0">
              {{ item.s_address }}
            </p>
            <!-- <p class="m-0 p-0">
              {{ $t('Flat No') }}: {{ item.delivery.flat_no }}
            </p>
            <p class="m-0 p-0">
              {{ $t('Building/Villa Name') }}: {{ item.delivery.building }}
            </p>
            <p class="m-0 p-0">
              {{ $t('Street') }}: {{ item.delivery.street }}
            </p> -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="card-footer bottom-button-block bg-white d-flex align-items-center"
      :class="{ 'justify-content-between': showTimer, 'justify-content-end': !showTimer }"
    >
      <div class="wrapper-card-actions-button">
        <img
            :src="require('@/assets/images/app-icons/seo.png')"
            class="mr-3"
            width="32"
            @click="that.openViewOrder(item)"
          >
          <button type="button" class="btn btn-primary" :disabled="item.status == 'WAITING_FOR_USER_APPROVAL'" @click="that.updateRequestApproval(item, nextStepStatus)">
          {{ $t(nextStep) }}
        </button>
      </div>
    </div>

  </div>
  <div v-else class="card bg-light swiper-slide map-card-icon-view">
    <div class="card-header main-header-container py-1 d-flex justify-content-between">
      <div class="name-tag">
        <p class="mb-0">
          {{ item.booking_id }}
        </p>
      </div>
      <div class="rate-block d-flex">
        <span class="price-tag mr-1 font-17"> {{ userCurrency }}{{ item.payment ? item.payment.total : 0 }} </span>
        <feather-icon icon="XIcon" size="20" @click="hideFromMap" />
      </div>
    </div>
    <div class="card-body bg-white">
      <div class="mt-1">
        <p class="mb-0 payment-mode-images font-15">
          {{ $t('Payment mode: ') }} {{ item.payment_mode }}
          <img v-if="item.payment_mode === 'CASH'" class="ml-1" :src="$helpers.getIcons('cash')">
          <img v-if="item.payment_mode === 'CARD'" class="ml-1" :src="$helpers.getIcons('card')">
          <img v-if="item.payment_mode === 'on_delivery'" class="ml-1" :src="$helpers.getIcons('card-on-delivery')">
          <img v-if="item.payment_mode === 'contact_less'" class="ml-1" :src="$helpers.getIcons('contact-less')">
        </p>
      </div>
    </div>
    <div class="card-footer bottom-button-block bg-white d-flex align-items-center justify-content-end">
      <div class="wrapper-card-actions-button">
        <button type="button" class="btn btn-primary" :disabled="item.status == 'WAITING_FOR_USER_APPROVAL'" @click="that.updateRequestApproval(item, nextStepStatus)">
          {{ $t(nextStep) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/libs/event-bus'
import {isEmpty} from '@/utilities'
export default {
  props: {
    that: {
      type: Object,
      default: () => { },
    },
    showIgnore: {
      type: Boolean,
      default: true,
    },
    showTimer: {
      type: Boolean,
      default: false,
    },
    showInMap: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      countDown: 60,
      interval: null,
    }
  },
  computed: {
    nextStep() {
      if (!this.item) return 'Accept'
      if (this.item.status === 'ASSIGNED') return 'On the way'
      if (this.item.status === 'PROCESSING') return 'Accept'
      if (this.item.status === 'STARTED') return 'Arrived'
      if (this.item.status === 'ARRIVED') return 'Started'
      if (this.item.status === 'PICKEDUP') return 'Task Done'
      if (this.item.status === 'DROPPED') return 'Completed'
      if (this.item.status === 'WAITING_FOR_USER_APPROVAL') return 'Wating for user Approval'
      return 'Accept'
    },
    nextStepStatus() {
      if (!this.item) return ''
      if (this.item.status === 'PROCESSING') return ''
      if (this.item.status === 'ASSIGNED') return 'STARTED'
      if (this.item.status === 'STARTED') return 'ARRIVED'
      if (this.item.status === 'ARRIVED') return 'PICKEDUP'
      if (this.item.status === 'PICKEDUP') return 'DROPPED'
      if (this.item.status === 'DROPPED') return 'COMPLETED'
      return ''
    },
    getBase() {
      // eslint-disable-next-line no-nested-ternary
      return this.item.payment ? this.item.payment : this.item.order_invoice ? this.item.order_invoice : {}
    },
    userCurrency() {
      return this.item && this.item.provider ? this.item.provider.currency_symbol : ''
    },
    invoiceTotal() {
      return this.getBase
    },
  },
  mounted() {
    this.countDownTimer()
  },
  destroyed() {
    if (this.interval) {
      clearTimeout(this.interval)
    }
  },
  methods: {
    isEmpty,
    storeActions(action) {
      if (action === 'message') {
        this.that.openStoreChat(this.item)
      } else if (action === 'call') {
        if (this.item && this.item.provider && this.item.provider.mobile) {
          window.open(`tel:+${this.item.provider.country_code ? this.item.provider.country_code : ''}${this.item.provider.mobile}`)
        }
      }
    },
    userActions(action) {
      if (action === 'message') {
        if (this.item && this.item.user && this.item.user.mobile) {
          window.open(`https://wa.me/${this.item.user.country_code ? this.item.user.country_code : ''}${this.item.user.mobile}/?text=Hello`)
        }
      } else if (action === 'call') {
        if (this.item && this.item.user && this.item.user.mobile) {
          window.open(`tel:+${this.item.user.country_code ? this.item.user.country_code : ''}${this.item.user.mobile}`)
        }
      }
    },
    showOnMap() {
      eventBus.$emit('show-details-on-map', this.item)
      this.$emit('hide-orders-modal')
    },
    hideFromMap() {
      eventBus.$emit('show-details-on-map', null)
    },
    openLocation(payload) {
      if (payload) {
        window.open(`https://maps.google.com/?q=${payload.latitude},${payload.longitude}`)
      }
    },
    countDownTimer() {
      if (this.showTimer) {
        if (this.countDown > 0) {
          this.interval = setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }
        if (this.countDown === 0) {
          this.that.ignoreOrder(this.item)
        }
      }
    },
  },
}
</script>

<style scoped>
.payment-mode-images img {
  width: 30px;
}

@media only screen and (max-width: 767px) {
  .map-card-icon-view {
    zoom: 0.6;
  }
}
</style>
